<template>
    <!-- Itinerário -->
    <div class="col-md-12 mt-4 mb-3 ">
        <h4 class="subtitle" v-if="hasSlot('subtitle')">
          <slot name="subtitle"></slot>
        </h4>
        <div class="row row-content">
          <div class="col-12 col-md-6">
            <span class="label">CNPJ/Vat Number:</span>
            <span> {{ $util.formatEmpty(value.cnpj_vatnumber) }}</span>
          </div>
          <div class="col-12 col-md-6">
            <span class="label">Nome de Empresa:</span>
            <span> {{ $util.formatEmpty(value.nome) }}</span>
          </div>
          <div class="col-12 col-md-6" v-if="value.pais">
            <span class="label">Pais:</span>
            <span> {{ $util.formatEmpty(value.pais.nome) }}</span>
          </div>
          <div class="col-12 col-md-6" v-if="isCEP && value.estado">
            <span class="label">Estado:</span>
            <span> {{ $util.formatEmpty(value.estado.nome) }}</span>
          </div>
          <div class="col-12 col-md-6" v-if="!isCEP">
            <span class="label">Estado:</span>
            <span> {{ $util.formatEmpty(value.ufExterior) }}</span>
          </div>
          <div class="col-12 col-md-6"  v-if="isCEP && value.cidade">
            <span class="label">Cidade:</span>
            <span> {{ $util.formatEmpty(value.cidade.nome) }}</span>
          </div>
          <div class="col-12 col-md-6"  v-if="!isCEP">
            <span class="label">Cidade:</span>
            <span> {{ $util.formatEmpty(value.municipioExterior) }}</span>
          </div>
          <div class="col-12 col-md-6">
            <span class="label">CEP:</span>
            <span> {{ $util.formatEmpty(value.cep) }}</span>
          </div>
          <div class="col-12 col-md-12">
            <span class="label">Endereço completo:</span>
            <span> {{ $util.formatEmpty(value.logradouro) }}</span>
          </div>
        </div>

        <h4 class="subtitle section mt-3" v-if="hasSlot('subtitle')">
          Contatos
        </h4>
        <div class="row row-content" v-for="(contato, index) in value.contatos" v-bind:key="index">
          <div class="col-12 col-md-4">
            <span class="label">Nome:</span>
            <span> {{ $util.formatEmpty(contato.nome) }}</span>
          </div>
          <div class="col-12 col-md-4">
            <span class="label">E-mail:</span>
            <span> {{ $util.formatEmpty(contato.email) }}</span>
          </div>
          <div class="col-12 col-md-4">
            <span class="label">Telefone:</span>
            <span> {{ $util.formatEmpty(contato.fone) }}</span>
          </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'FormConfirmPerson',
  data () {
    return {
      id: null
    }
  },
  computed: {
    isCEP () {
      return this.value.pais && this.value.pais.id === 22
    }
  },
  methods: {
    hasSlot (name = 'default') {
      return !!this.$slots[ name ] || !!this.$scopedSlots[ name ]
    }
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
  .marked {
    padding: 25px;
    background-color: #f7f7f7;
    color: #5a4d34;
  }

  .label {
    color: #a7acae;
    display: inline-block;
    /* width: 45%; */
    max-width: 235px;
  }

  .row-content > .col-12 {
    border-bottom: 1px solid #e8e5de;
    line-height: 2.2em;
  }
</style>
