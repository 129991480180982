<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <strong v-if="label" >{{ label }}</strong> <br v-if="label"  />
    <ul class="files btn-link no-padding" v-for="(file, index) in value.arquivos" :key="index" v-on:click="fileDownload(file)">
      <div class="file-align">
        <li class="files-item"><i class="fa fa-file"></i></li>
        <div class="file-date">
          <li class="files-item"> {{ file.originalFilename }}</li>
          <li class="files-item-date">{{ $util.formatDate(file.date) }} </li>
        </div>
      </div>
      <li class="files-item"><button class="btn btn-link no-padding"> <i class="ft-download"></i></button></li>
    </ul>
  </div>
</template>

<script>
import ProposalService from '@/services/ProposalService'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  inheritAttrs: false,
  name: 'ShowFiles',
  components: {
    Loading
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      optionList: [],
      timer: 0,
      selected: null
    }
  },
  $_veeValidate: {
    // value getter
    value () {
      return this.$el.value
    },
    // name getter
    name () {
      return this.name
    }
  },
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      required: true
    }
  },
  methods: {
    fileDownload (file) {
      this.isLoading = true
      ProposalService.getFile(this.value.id, file.filename).then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', file.originalFilename)
        global.document.body.appendChild(link)
        link.click()

        this.isLoading = false
      })
    }
  },
  watch: {
    value: function (newValue) {
      this.selected = newValue
    }
  }
}
</script>

<style scoped>
.files{
  list-style-type: none;
  max-width: 96%;;
  min-width: 10vw;
  margin-right: 6px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 0 1% 0 1%;
  border: 1px solid #464646;
  border-radius: 8px;
}

.file-align{
  display:flex;
  align-items: center;
}

.files-item-date{
  font-weight: 100;
  font-size: 10px;
}

.file-date{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px;
}
</style>
